<template>
  <header>
    <div class="container-fluid">
      <div class="row d-flex align-items-center" style="padding: 0px">
        <div class="col-3">
          <figure>
            <a @click.stop="goHome">
              <img src="logo-fluke.svg" alt="Logo Fluke" />
            </a>
          </figure>
        </div>
        <div class="col-9 text-end">
          <nav>
            <div class="btn-group">
              <a
                @click="setLanguage('es')"
                class="btn btn-warning"
                :class="{ active: $i18n.locale == 'es' }"
                >ES</a
              >
              <a
                @click="setLanguage('en')"
                class="btn btn-warning"
                :class="{ active: $i18n.locale == 'en' }"
                >EN</a
              >
              <a
                @click="setLanguage('pt')"
                class="btn btn-warning"
                :class="{ active: $i18n.locale == 'pt' }"
                >PT</a
              >
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderMenu",
  methods: {
    goHome() {
      this.$router.push({
        name: "Home",
      });
    },
    setLanguage(language) {
      let lang = language.toLowerCase();
      this.$i18n.locale = lang;
    },
  },
};
</script>
