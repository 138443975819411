import term from "@/utils/termsText"
import policies from "@/utils/privacyText"

export default {
  general: {
    back: "Voltar",
  },
  home: {
    title: "Novo APP FLUKE VSA",
    subtitle: "Com o novo App VSA você:",
    list: {
      access: "• Tem acesso rápido a toda linha de produtos Fluke",
      materials: "• Encontra materiais de apoio para suas vendas",
      news: "• Receba as últimas novidades da Fluke",
      training: "• Treinamentos em vídeo",
      catalog: "• Catálogo de produtos",
    },
    download: "Baixe já o novo app Fluke VSA"
  },
  deleteAccount: {
    title: "Como excluir minha conta?",
    list: {
      step1: "Passo 1: Faça login em sua conta no Fluke APP VSA",
      step2: "Passo 2: Vá para Configurações de Perfil",
      step3: "Passo 3: Clique no botão 'Deletar Conta'",
      step4: "Passo 4: Confirme a exclusão da conta",
      step5: "Passo 5: Pronto! Sua conta foi excluída",
    },
    warning: "Esta ação é irreversível e seus dados serão excluídos permanentemente.",
  },
  termsOfUse: {
    title: "Termos de Uso",
    content: term.pt
  },
  privacyPolicy: {
    title: "Política de Privacidade",
    content: policies.pt
  },
  footer: {
    copyright: "© %{year} Fluke Corporation. Todos os direitos reservados.",
    deleteAccount: "Como excluir minha conta?",
    privacyPolicy: "Política de Privacidade",
    termsOfUse: "Termos de Uso",
  }
}