<template>
  <footer>
    <div class="container-fluid">
      <div class="row align-items-center">
        <div
          v-html="$t('footer.copyright', { year: getYear() })"
          class="col-md-6 col-lg-4 order-2 order-lg-1 offset-lg-4 text-center"
        ></div>
        <div
          class="col-md-6 col-lg-4 order-1 order-lg-2 text-center text-md-right"
        >
          <nav>
            <a @click.stop="goToDeleteAccount">{{
              $t("footer.deleteAccount")
            }}</a>
            <a @click.stop="goToTermsUse" class="">{{
              $t("footer.termsOfUse")
            }}</a>
            <a @click.stop="goToPrivacyPolicy" class="">{{
              $t("footer.privacyPolicy")
            }}</a>
          </nav>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "BaseFooter",
  methods: {
    getYear() {
      return new Date().getFullYear();
    },
    goToTermsUse() {
      this.$router.push({
        name: "TermOfUse",
      });
    },
    goToPrivacyPolicy() {
      this.$router.push({
        name: "PrivacyPolicy",
      });
    },
    goToDeleteAccount() {
      this.$router.push({
        name: "DeleteAccount",
      });
    },
  },
};
</script>
