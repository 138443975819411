import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import router from './routes/router'
import { BootstrapVue } from 'bootstrap-vue'
import VueRouter from 'vue-router'
import HeaderMenu from './components/HeaderMenu.vue'
import BaseFooter from './components/BaseFooter.vue'

import EN_LANG from './i18n/en';
import PT_LANG from './i18n/pt';
import ES_LANG from './i18n/es';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(VueRouter)
Vue.use(VueI18n)

const message = {
  en: { ...EN_LANG },
  pt: { ...PT_LANG },
  es: { ...ES_LANG }
}

let lang = navigator.language.slice(0, 2)

export const i18n = new VueI18n({
  locale: lang.toLowerCase(),
  messages: message
})

Vue.component('HeaderMenu', HeaderMenu)
Vue.component('BaseFooter', BaseFooter)

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
