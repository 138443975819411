import term from "@/utils/termsText"
import policies from "@/utils/privacyText"

export default {
  general: {
    back: "Volver",
  },
  home: {
    title: "Nuevo APP FLUKE VSA",
    subtitle: "Con la nueva App VSA usted:",
    list: {
      access: "• Tiene acceso rápido a toda la línea de productos Fluke",
      materials: "• Encuentra materiales de apoyo para sus ventas",
      news: "• Reciba las últimas novedades de Fluke",
      training: "• Entrenamientos en video",
      catalog: "• Catálogo de productos",
    },
    download: "Descargue ya la nueva app Fluke VSA"
  },
  deleteAccount: {
    title: "¿Cómo eliminar mi cuenta?",
    list: {
      step1: "Paso 1: Inicie sesión en su cuenta en Fluke APP VSA",
      step2: "Paso 2: Vaya a Configuración de Perfil",
      step3: "Paso 3: Haga clic en el botón 'Eliminar Cuenta'",
      step4: "Paso 4: Confirme la eliminación de la cuenta",
      step5: "Paso 5: ¡Listo! Su cuenta ha sido eliminada",
    },
    warning: "Esta acción es irreversible y sus datos serán eliminados permanentemente.",
  },
  termsOfUse: {
    title: "Términos de Uso",
    content: term.es
  },
  privacyPolicy: {
    title: "Política de Privacidad",
    content: policies.es
  },
  footer: {
    copyright: "© %{year} Fluke Corporation. Todos los derechos reservados.",
    deleteAccount: "¿Cómo eliminar mi cuenta?",
    privacyPolicy: "Política de Privacidad",
    termsOfUse: "Términos de Uso",
  }
}