const policies = {
  pt: `
Fluke Assistente de Vendas Virtual - Política de Privacidade<br /><br />
Somos a FLUKE DO BRASIL LTDA., Empresa devidamente constituída e existente de acordo com as leis do Brasil, com sede na Avenida Maria Coelho Aguiar, nº. 215, Bloco F, Andar Panamby, Loja 84C, Jardim São Luís, CEP 05805-000, na cidade de São Paulo, Estado de São Paulo, inscrito na contribuinte sob o nº 02.370.758 / 0001-47 (“Fluke”, "Nós" ou "nosso").<br /><br />
A sua privacidade e a segurança dos seus dados pessoais são muito importantes para nós. Nesta Política de Privacidade (ou simplesmente "Política"), explicamos quais informações e dados pessoais coletamos dos usuários do aplicativo Assistente de Vendas Virtual e todos os seus subdomínios, programas e extensões ("aplicativo VSA"), por que coletamos, medidas de segurança e outras informações relevantes.<br /><br />
Preparamos uma tabela com uma breve descrição dos termos desta Política. Não descreve todo o conteúdo desta Política, mas apenas um resumo. Portanto, leia a Política completa para entender como gerenciamos e protegemos sua privacidade e dados pessoais ao usar o aplicativo VSA.<br /><br />
Tabela de resumo:<br />
1. Escopo de aplicação e data efetiva<br />
Explicamos a quem esta Política se aplica e a data de entrada em vigor da Política.<br /><br />
2 Informações que coletamos de você e objetivos<br />
Explicamos as categorias de informações e dados pessoais que coletamos dos usuários do aplicativo VSA, que são estritamente necessários para usar o aplicativo e quais não são, por que os coletamos e como os usamos.<br /><br />
3. Compartilhe seus dados pessoais<br />
Explicamos que podemos compartilhar seus dados e informações com: Empresas Fluke fora do Brasil; técnicas de operação de provedores de serviços e infraestrutura relacionadas ao aplicativo VSA; Polícia e autoridades de proteção de dados; e um terceiro somente com o consentimento prévio do Usuário. Por favor, leia esta seção para mais informações.<br /><br />
4. Correção, retenção e exclusão de dados<br />
Explicamos como corrigir ou atualizar quaisquer dados pessoais, o período de retenção de suas informações e as situações para sua exclusão.<br /><br />
5. Transferência para outros países<br />
Explicamos em quais situações seus dados pessoais podem ser transferidos para outros países.<br /><br />
6. Segurança e proteção<br />
Explicamos as medidas que tomamos para salvaguardar os seus dados pessoais.<br /><br />
7. Seus direitos<br />
Listamos todos os direitos de dados pessoais concedidos a pessoas físicas no Brasil pela legislação brasileira<br /><br />
8. Mudanças nesta política<br />
Explicamos o que acontecerá em caso de alteração dos termos desta Política.<br /><br />
9. Como entrar em contato conosco<br />
Fornecemos nossos dados de contato.<br /><br />
1. Escopo de aplicação e data efetiva<br />
Esta Política é aplicável a você se você for um usuário de aplicativo VSA no Brasil. Acesse estes Termos de Uso para entender as condições aplicáveis para usar o aplicativo VSA.<br /><br />
O aplicativo VSA foi desenvolvido para os parceiros de vendas da Fluke no Brasil e na América Latina, incluindo distribuidores e representantes de vendas, e não se destina a menores de 18 anos, portanto, não coletamos nem vendemos dados de menores.<br /><br />
Esta Política de Privacidade entra em vigor em 10 de fevereiro de 2020.<br /><br />
2. Informações que coletamos e propósitos<br />
Nesta seção, você encontrará as categorias de dados pessoais e informações que coletaremos de você e do uso do aplicativo VSA em seu dispositivo e suas finalidades. E você também aprenderá quais deles você realmente precisa fornecer para usar o aplicativo e quais são opcionais.<br /><br />
É importante que você compreenda que tem opções em relação aos dados pessoais que coletamos. Você pode controlar quais informações fornecerá ou não, dependendo do que deseja do aplicativo VSA.<br /><br />
Nós retemos os dados e informações do usuário apenas enquanto sua conta estiver ativa ou conforme necessário para lhe fornecer serviços. Também retemos e usamos suas informações conforme necessário para cumprir nossas obrigações legais.<br /><br />
Informações que os usuários nos fornecem diretamente<br />
Abaixo, você encontrará os dados e informações pessoais que pode nos fornecer: Dados necessários para usar o aplicativo VSA<br /><br />
Categorias de dados:<br /><br />
DADOS DO USUÁRIO<br />
Nome e sobrenome<br />
Endereço de email<br />
Senha<br /><br />
Objetivos: coletamos essas informações para que você possa criar uma conta de usuário, acessar o aplicativo VSA, habilitar a navegação no aplicativo, habilitar o uso dos serviços fornecidos pelo VSA e entrar em contato com você. Sem fornecer as informações acima, não será possível acessar ou usar o aplicativo VSA.<br /><br />
Dados para aumentar sua experiência com o aplicativo VSA<br /><br />
Categoria de dados:<br /><br />
DADOS DO USUÁRIO<br />
Objetivos:<br />
Número de telefone<br />
Nome e localização da empresa (país, código postal, cidade, estado)<br />
Função na empresa<br />
Número de telefone adicional<br /><br />
DADOS DO DISPOSITIVO DO USUÁRIO E USO DO APLICATIVO<br />
Câmera<br />
Agenda telefônica<br />
Impressão digital<br />
Network<br />
Chamadas telefônicas<br /><br />
Coletamos essas informações para aprimorar sua experiência e habilitar recursos e funcionalidades adicionais do aplicativo VSA, como ligar para um número de telefone diretamente do aplicativo.<br /><br />
Essas informações não são necessárias para usar o aplicativo VSA, mas podem aumentar sua experiência de uso.<br /><br />
Sempre pediremos seu consentimento antes de usar esses dados.<br /><br />
Informações que coletamos automaticamente do uso do aplicativo VSA<br /><br />
Abaixo, você encontrará os dados pessoais e as informações que coletamos automaticamente de qualquer usuário do aplicativo VSA:<br />
Dados necessários para usar o aplicativo VSA<br /><br />
Categorias de dados:<br /><br />
INFORMAÇÕES TÉCNICAS E DE USO<br />
Endereço IP, local e hora de acesso<br />
Informações do dispositivo (tipo, ID, modelo e fabricante)<br /><br />
Finalidades da versão do aplicativo:<br /><br />
Coletamos essas informações para permitir a navegação no aplicativo e o cumprimento das obrigações legais.<br />
Dados para aumentar sua experiência com o aplicativo VSA<br /><br />
Categorias de dados:<br /><br />
INFORMAÇÕES TÉCNICAS E DE USO<br />
Cookies e tecnologias semelhantes para rastrear o uso do aplicativo<br />
Objetivos: coletamos essas informações para mantê-lo conectado, lembrar suas preferências e identificar você e seu dispositivo.<br />
Sempre solicite seu consentimento antes de usar esses dados.<br /><br />
Controle de seus dados pessoais<br />
Você pode gerenciar seus dados para gerenciar como a Fluke usa seus dados pessoais, atualizando-os, excluindo parte deles ou excluindo sua conta.<br /><br />
Acesse seu perfil no painel do VSA.<br /><br />
Sobre seus dados<br />
É importante esclarecer que, de acordo com a legislação brasileira, “dados pessoais” são quaisquer informações relacionadas a uma pessoa física identificada ou identificável. Alguns dados coletados por nós, como nome, e-mail e endereço IP (Protocolo de Internet), são dados pessoais.<br />
No entanto, não inclui informações agregadas não pessoalmente identificáveis que não identifiquem um usuário ou que não possam ser razoavelmente vinculadas ou de outra forma conectadas. Podemos usar essas informações agregadas não pessoalmente identificáveis para operar, analisar, melhorar e otimizar o aplicativo VSA.<br /><br />
3. Compartilhe seus dados pessoais<br />
Podemos compartilhar os dados pessoais ou informações privadas do usuário com:<br /><br />
O grupo Fluke de empresas fora do Brasil, incluindo Fluke Corporation, com sede em 6920 Seaway Blvd., Everett, WA 98203, Estados Unidos, e distribuidores Fluke locais na América Latina, com o objetivo de verificar se o usuário faz parte do canal de vendas da Fluke América Latina e conceder acesso ao aplicativo, bem como determinar as informações locais relevantes sobre férias e promoções e que possam ser compartilhadas com os Usuários do aplicativo;<br /><br />
Prestadores de serviços que operam técnicos e de infraestrutura relacionados para o aplicativo VSA, como segurança e serviços de TI, desde que aceitem restrições de privacidade semelhantes às desta Política de Privacidade, assinando acordos de proteção de dados ou compromissos semelhantes;<br /><br />
Polícia e autoridades de proteção de dados, para cumprir as obrigações legais;<br /><br />
E/ou terceiros somente com o consentimento prévio do Usuário.<br /><br />
Não vendemos seus dados pessoais ou informações privadas.<br /><br />
4. Correção, retenção e exclusão de dados<br />
Caso deseje corrigir, atualizar ou excluir alguma informação pessoal, entre em contato com marketing@fluke.com.br.<br /><br />
Mesmo após a exclusão de seus dados pessoais, podemos mantê-los usando medidas seguras para cumprir as obrigações legais ou em outra situação permitida pela legislação brasileira.<br /><br />
5. Transferência para outros países<br />
Podemos transferir os dados pessoais ou informações privadas do Usuário para outros países para:<br /><br />
Compartilhe-os com o grupo Fluke de empresas fora do Brasil, incluindo a Fluke Corporation sediada em 6920 Seaway Blvd., Everett, WA 98203, Estados Unidos, e distribuidores Fluke locais na América Latina, a fim de verificar se o usuário está parte do canal de vendas da Fluke para a América Latina e conceder acesso ao aplicativo, e para determinar informações relevantes sobre feriados e promoções, podendo ser compartilhadas com os usuários do aplicativo;<br /><br />
E compartilhá-lo com os prestadores de serviços que operam a infraestrutura técnica e relacionados ao aplicativo VSA, como serviços de TI e segurança, desde que tenham acordado restrições de privacidade semelhantes às desta Política de Privacidade, assinando acordos de proteção de dados ou a adoção de compromissos semelhantes.<br /><br />
6. Segurança e proteção<br />
Temos o compromisso de proteger os dados pessoais do Usuário. Tomamos todas as medidas técnicas e organizacionais apropriadas para ajudar a proteger a segurança de seus dados pessoais, protegê-los de acesso não autorizado, alteração ou destruição e manter a precisão dos dados. Para obter mais detalhes, consulte a Declaração de Política de Privacidade Global da Fluke em: https://www.fluke.com/en-us/fluke/privacy-policy<br /><br />
Suas informações são armazenadas em um servidor seguro e sempre que for necessário identificar ou fornecer informações confidenciais, os dados serão transmitidos entre o seu dispositivo e o aplicativo Fluke por meio de uma conexão segura.<br /><br />
No entanto, lembre-se de que nenhum sistema é 100% seguro. Sua senha protege sua conta de usuário. Recomendamos que você sempre use uma senha forte que seja exclusiva para sua conta do aplicativo VSA e nunca compartilhe sua senha com ninguém.<br /><br />
Todas as informações coletadas nos aplicativos serão armazenadas em nosso banco de dados sem interferência humana. Além disso, todas as análises realizadas com suas informações individuais também serão realizadas por processamento eletrônico, e os resultados apresentados serão sempre resumos estatísticos, nos quais o seu nome ou qualquer outra informação pessoal nunca aparecerá.<br /><br />
7. Seus direitos<br />
A legislação brasileira concede certos direitos aos indivíduos em relação aos seus dados pessoais. Portanto, os direitos abaixo são concedidos a todos os usuários do aplicativo VSA, exceto conforme limitado pela legislação aplicável:<br /><br />
Direito de confirmação: o direito de confirmar a existência do seu tratamento de dados pessoais por nós;<br /><br />
Direito de acesso: o direito de ser informado e solicitar acesso aos dados pessoais que processamos sobre você;<br /><br />
Direito de retificação: direito de solicitar que modifiquemos ou atualizemos seus dados pessoais quando eles estiverem imprecisos ou incompletos;<br /><br />
Direito ao anonimato: o direito de tornar anônimo, bloquear ou excluir qualquer informação desnecessária ou excesso de dados ou dados processados em violação à legislação brasileira;<br /><br />
Direito à portabilidade dos dados: o direito de solicitar uma cópia dos seus dados pessoais em formato eletrónico e o direito de transmitir esses dados pessoais para utilização no serviço de outra parte;<br /><br />
Direito de apagamento: o direito de solicitar que apagemos seus dados pessoais que estão sendo processados com base em seu consentimento;<br /><br />
Direito à informação sobre compartilhamento: o direito de solicitar informações de entidades públicas e privadas com as quais o controlador de dados compartilhou os dados;<br /><br />
Direito à informação sobre o consentimento: direito a solicitar informação sobre a possibilidade de não dar o consentimento e sobre as consequências da recusa; Direito de revogar o consentimento: o direito de revogar o consentimento previamente concedido.<br /><br />
Se você tiver alguma dúvida sobre sua privacidade, seus direitos ou como exercê-los, entre em contato com nosso Diretor de Proteção de Dados por qualquer um dos métodos listados abaixo:<br /><br />
Email: marketing@fluke.com.br<br />
Telefone: +55 11 3530-89018<br /><br />
8. Mudanças nesta política<br />
Iremos revisar e atualizar esta Política periodicamente à luz das mudanças nas práticas de negócios, tecnologia e requisitos legais. Se fizermos quaisquer alterações significativas ou materiais, você será notificado por e-mail.<br /><br />
9.Como entrar em contato conosco<br />
Se você tiver dúvidas ou preocupações, ou deseja exercer seus direitos de privacidade, convidamos você a entrar em contato com nosso Diretor de Proteção de Dados por qualquer um dos métodos listados abaixo:<br /><br />
Email: marketing@fluke.com.br<br />
Telefone: +55 11 3530-8901
`,
  en: `
Fluke Virtual Sales Assistant - Privacy Policy<br /><br />
We are FLUKE DO BRASIL LTDA., a company duly constituted and existing in accordance with the laws of Brazil, headquartered at Avenida Maria Coelho Aguiar, No. 215, Block F, Andar Panamby, Loja 84C, Jardim São Luís, CEP 05805-000, in the city of São Paulo, State of São Paulo, registered under taxpayer number 02.370.758/0001-47 (“Fluke”, "We" or "our").<br /><br />
Your privacy and the security of your personal data are very important to us. In this Privacy Policy (or simply "Policy"), we explain what information and personal data we collect from users of the Virtual Sales Assistant application and all its subdomains, programs, and extensions ("VSA app"), why we collect it, security measures, and other relevant information.<br /><br />
We have prepared a table with a brief description of the terms of this Policy. It does not describe all the content of this Policy, but only a summary. Therefore, please read the full Policy to understand how we manage and protect your privacy and personal data when using the VSA app.<br /><br />
Summary Table:<br />
1. Scope of application and effective date<br />
We explain to whom this Policy applies and the effective date of the Policy.<br /><br />
2. Information we collect from you and purposes<br />
We explain the categories of information and personal data that we collect from users of the VSA app, which are strictly necessary to use the app and which are not, why we collect them, and how we use them.<br /><br />
3. Share your personal data<br />
We explain that we may share your data and information with: Fluke companies outside Brazil; service providers operating technical and infrastructure services related to the VSA app; Police and data protection authorities; and a third party only with the prior consent of the User. Please read this section for more information.<br /><br />
4. Correction, retention, and deletion of data<br />
We explain how to correct or update any personal data, the retention period of your information, and the situations for its deletion.<br /><br />
5. Transfer to other countries<br />
We explain in which situations your personal data may be transferred to other countries.<br /><br />
6. Security and protection<br />
We explain the measures we take to safeguard your personal data.<br /><br />
7. Your rights<br />
We list all personal data rights granted to individuals in Brazil by Brazilian legislation<br /><br />
8. Changes in this policy<br />
We explain what will happen in case of changes in the terms of this Policy.<br /><br />
9. How to contact us<br />
We provide our contact details.<br /><br />
1. Scope of application and effective date<br />
This Policy applies to you if you are a user of the VSA app in Brazil. Access these Terms of Use to understand the applicable conditions for using the VSA app.<br /><br />
The VSA app was developed for Fluke's sales partners in Brazil and Latin America, including distributors and sales representatives, and is not intended for minors under 18 years of age, so we do not collect or sell data from minors.<br /><br />
This Privacy Policy comes into effect on February 10, 2020.<br /><br />
2. Information we collect and purposes<br />
In this section, you will find the categories of personal data and information that we will collect from you and from the use of the VSA app on your device and its purposes. And you will also learn which ones you really need to provide to use the app and which are optional.<br /><br />
It is important that you understand that you have options regarding the personal data we collect. You can control which information you will or will not provide depending on what you want from the VSA app.<br /><br />
We retain user data and information only while their account is active or as necessary to provide services to them. We also retain and use your information as necessary to comply with our legal obligations.<br /><br />
Information users provide us directly<br />
Below you will find the personal data and information you may provide us: Data required to use the VSA app<br /><br />
Categories of data:<br /><br />
USER DATA<br />
First and last name<br />
Email address<br />
Password<br /><br />
Purposes: We collect this information so that you can create a user account, access the VSA app, enable navigation in the app, enable the use of services provided by the VSA, and contact you. Without providing the above information, it will not be possible to access or use the VSA app.<br /><br />
Data to enhance your experience with the VSA app<br /><br />
Data category:<br /><br />
USER DATA<br />
Purposes:<br />
Phone number<br />
Name and location of the company (country, postal code, city, state)<br />
Function in the company<br />
Additional phone number<br /><br />
USER DEVICE DATA AND APP USAGE<br />
Camera<br />
Phone book<br />
Fingerprint<br />
Network<br />
Phone calls<br /><br />
We collect this information to enhance your experience and enable additional features and functionalities of the VSA app, such as calling a phone number directly from the app.<br /><br />
This information is not necessary to use the VSA app but can enhance your user experience.<br /><br />
We will always ask for your consent before using this data.<br /><br />
Information we collect automatically from the use of the VSA app<br /><br />
Below you will find personal data and information that we automatically collect from any user of the VSA app:<br />
Data required to use the VSA app<br /><br />
Categories of data:<br /><br />
TECHNICAL AND USAGE INFORMATION<br />
IP address, location, and access time<br />
Device information (type, ID, model, and manufacturer)<br /><br />
App version purposes:<br /><br />
We collect this information to enable navigation in the app and compliance with legal obligations.<br />
Data to enhance your experience with the VSA app<br /><br />
Categories of data:<br /><br />
TECHNICAL AND USAGE INFORMATION<br />
Cookies and similar technologies to track app usage<br />
Purposes: We collect this information to keep you logged in, remember your preferences, and identify you and your device.<br />
Always request your consent before using this data.<br /><br />
Control of your personal data<br />
You can manage your data to manage how Fluke uses your personal data, updating it, deleting part of it, or deleting your account.<br /><br />
Access your profile in the VSA dashboard.<br /><br />
About your data<br />
It is important to clarify that, according to Brazilian legislation, "personal data" is any information related to an identified or identifiable natural person. Some data collected by us, such as name, email, and IP address (Internet Protocol), are personal data.<br />
However, it does not include aggregated, non-personally identifiable information that does not identify a user or cannot be reasonably linked or otherwise connected. We may use such aggregated, non-personally identifiable information to operate, analyze, improve, and optimize the VSA app.<br /><br />
3. Share your personal data<br />
We may share the personal data or private information of the user with:<br /><br />
The Fluke group of companies outside Brazil, including Fluke Corporation, headquartered at 6920 Seaway Blvd., Everett, WA 98203, United States, and local Fluke distributors in Latin America, in order to verify whether the user is part of the Fluke Latin America sales channel and grant access to the app, as well as determine relevant local information about holidays and promotions that may be shared with the app Users;<br /><br />
Service providers operating technical and infrastructure related to the VSA app, such as security and IT services, provided they agree to similar privacy restrictions to those of this Privacy Policy, by signing data protection agreements or similar commitments;<br /><br />
Police and data protection authorities, to fulfill legal obligations;<br /><br />
And/or third parties only with the prior consent of the User.<br /><br />
We do not sell your personal data or private information.<br /><br />
4. Correction, retention, and deletion of data<br />
If you wish to correct, update, or delete any personal information, please contact marketing@fluke.com.br.<br /><br />
Even after deleting your personal data, we may keep it using secure measures to comply with legal obligations or in another situation permitted by Brazilian legislation.<br /><br />
5. Transfer to other countries<br />
We may transfer the user's personal data or private information to other countries for:<br /><br />
Share them with the Fluke group of companies outside Brazil, including Fluke Corporation based at 6920 Seaway Blvd., Everett, WA 98203, United States, and local Fluke distributors in Latin America, to verify whether the user is part of the Fluke sales channel for Latin America and grant access to the app, and to determine relevant information about holidays and promotions, which can be shared with the users of the app;<br /><br />
And share it with service providers operating technical infrastructure related to the VSA app, such as IT and security services, provided they have agreed to privacy restrictions similar to those of this Privacy Policy, by signing data protection agreements or adopting similar commitments.<br /><br />
6. Security and protection<br />
We are committed to protecting the user's personal data. We take all appropriate technical and organizational measures to help protect the security of your personal data, protect it from unauthorized access, alteration, or destruction, and maintain data accuracy. For more details, see Fluke's Global Privacy Policy Statement at: https://www.fluke.com/en-us/fluke/privacy-policy<br /><br />
Your information is stored on a secure server and whenever it is necessary to identify or provide confidential information, data will be transmitted between your device and the Fluke app through a secure connection.<br /><br />
However, please remember that no system is 100% secure. Your password protects your user account. We recommend that you always use a strong password that is unique to your VSA app account and never share your password with anyone.<br /><br />
All information collected in the apps will be stored in our database without human interference. In addition, all analyzes conducted with your individual information will also be carried out by electronic processing, and the results presented will always be statistical summaries, in which your name or any other personal information will never appear.<br /><br />
7. Your rights<br />
Brazilian legislation grants certain rights to individuals regarding their personal data. Therefore, the rights below are granted to all users of the VSA app, except as limited by applicable law:<br /><br />
Right to confirmation: the right to confirm the existence of your personal data processing by us;<br /><br />
Right of access: the right to be informed and request access to the personal data we process about you;<br /><br />
Right to rectification: the right to request that we modify or update your personal data when it is inaccurate or incomplete;<br /><br />
Right to anonymity: the right to anonymize, block, or delete any unnecessary or excess information or data processed in violation of Brazilian legislation;<br /><br />
Right to data portability: the right to request a copy of your personal data in electronic format and the right to transmit this personal data for use in another party's service;<br /><br />
Right to erasure: the right to request that we erase your personal data that is being processed based on your consent;<br /><br />
Right to information about sharing: the right to request information from public and private entities with which the data controller has shared the data;<br /><br />
Right to information about consent: the right to request information about the possibility of not giving consent and about the consequences of refusal; Right to revoke consent: the right to revoke previously given consent.<br /><br />
If you have any questions about your privacy, your rights, or how to exercise them, please contact our Data Protection Officer by any of the methods listed below:<br /><br />
Email: marketing@fluke.com.br<br />
Phone: +55 11 3530-89018<br /><br />
8. Changes in this policy<br />
We will review and update this Policy periodically in light of changes in business practices, technology, and legal requirements. If we make any significant or material changes, you will be notified by email.<br /><br />
9. How to contact us<br />
If you have any questions or concerns, or wish to exercise your privacy rights, we invite you to contact our Data Protection Officer using any of the methods listed below:<br /><br />
Email: marketing@fluke.com.br<br />
Phone: +55 11 3530-8901
`,
  es: `
Asistente Virtual de Ventas Fluke - Política de privacidad<br /><br />
Somos FLUKE DO BRASIL LTDA., una sociedad debidamente constituida y existente de acuerdo con las leyes de Brasil, con domicilio en Avenida Maria Coelho Aguiar, No. 215, Bloque F, Andar Panamby, Loja 84C, Jardim São Luís, CEP 05805-000, en la ciudad de São Paulo, Estado de São Paulo, inscrita en el número de contribuyente 02.370.758/0001-47 (“Fluke”, "Nosotros" o "nuestro").<br /><br />
Tu privacidad y la seguridad de tus datos personales son muy importantes para nosotros. En esta Política de Privacidad (o simplemente "Política"), explicamos qué información y datos personales recopilamos de los usuarios de la aplicación Asistente Virtual de Ventas y todos sus subdominios, programas y extensiones ("app VSA"), por qué los recopilamos, las medidas de seguridad y otra información relevante.<br /><br />
Hemos preparado una tabla con una breve descripción de los términos de esta Política. No describe todo el contenido de esta Política, sino solo un resumen. Por lo tanto, lea la Política completa para comprender cómo gestionamos y protegemos su privacidad y datos personales al usar la app VSA.<br /><br />
Tabla de resumen:<br />
1. Ámbito de aplicación y fecha de vigencia<br />
Explicamos a quién se aplica esta Política y la fecha de vigencia de la Política.<br /><br />
2. Información que recopilamos de usted y fines<br />
Explicamos las categorías de información y datos personales que recopilamos de los usuarios de la app VSA, cuáles son estrictamente necesarios para utilizar la app y cuáles no, por qué los recopilamos y cómo los utilizamos.<br /><br />
3. Compartir sus datos personales<br />
Explicamos que podemos compartir sus datos e información con: empresas Fluke fuera de Brasil; proveedores de servicios que operan servicios técnicos e infraestructura relacionados con la app VSA; Policía y autoridades de protección de datos; y un tercero solo con el consentimiento previo del Usuario. Lea esta sección para obtener más información.<br /><br />
4. Corrección, retención y eliminación de datos<br />
Explicamos cómo corregir o actualizar cualquier información personal, el período de retención de su información y las situaciones para su eliminación.<br /><br />
5. Transferencia a otros países<br />
Explicamos en qué situaciones se pueden transferir sus datos personales a otros países.<br /><br />
6. Seguridad y protección<br />
Explicamos las medidas que tomamos para salvaguardar sus datos personales.<br /><br />
7. Sus derechos<br />
Enumeramos todos los derechos de datos personales otorgados a las personas en Brasil por la legislación brasileña<br /><br />
8. Cambios en esta política<br />
Explicamos qué sucederá en caso de cambios en los términos de esta Política.<br /><br />
9. Cómo ponerse en contacto con nosotros<br />
Proporcionamos nuestros datos de contacto.<br /><br />
1. Ámbito de aplicación y fecha de vigencia<br />
Esta Política se aplica a usted si es usuario de la app VSA en Brasil. Acceda a estos Términos de Uso para comprender las condiciones aplicables para utilizar la app VSA.<br /><br />
La app VSA fue desarrollada para los socios de ventas de Fluke en Brasil y América Latina, incluidos los distribuidores y representantes de ventas, y no está destinada a menores de 18 años, por lo que no recopilamos ni vendemos datos de menores.<br /><br />
Esta Política de Privacidad entra en vigencia el 10 de febrero de 2020.<br /><br />
2. Información que recopilamos y fines<br />
En esta sección, encontrará las categorías de datos personales e información que recopilaremos de usted y del uso de la app VSA en su dispositivo y sus fines. Y también aprenderá cuáles realmente debe proporcionar para usar la app y cuáles son opcionales.<br /><br />
Es importante que entienda que tiene opciones con respecto a los datos personales que recopilamos. Puede controlar qué información desea o no proporcionar según lo que desee de la app VSA.<br /><br />
Retenemos datos e información de usuarios solo mientras su cuenta esté activa o según sea necesario para brindarles servicios. También retenemos y utilizamos su información según sea necesario para cumplir con nuestras obligaciones legales.<br /><br />
Información que los usuarios nos proporcionan directamente<br />
A continuación, encontrará los datos personales e información que puede proporcionarnos: Datos necesarios para utilizar la app VSA<br /><br />
Categorías de datos:<br /><br />
DATOS DE USUARIO<br />
Nombre y apellido<br />
Dirección de correo electrónico<br />
Contraseña<br /><br />
Fines: Recopilamos esta información para que pueda crear una cuenta de usuario, acceder a la app VSA, habilitar la navegación en la app, habilitar el uso de los servicios proporcionados por la app VSA y contactarlo. Sin proporcionar la información anterior, no será posible acceder o utilizar la app VSA.<br /><br />
Datos para mejorar su experiencia con la app VSA<br /><br />
Categoría de datos:<br /><br />
DATOS DE USUARIO<br />
Número de teléfono<br />
Nombre y ubicación de la empresa (país, código postal, ciudad, estado)<br />
Función en la empresa<br />
Número de teléfono adicional<br /><br />
DATOS DEL DISPOSITIVO DEL USUARIO Y USO DE LA APP<br />
Cámara<br />
Agenda telefónica<br />
Huellas digitales<br />
Red<br />
Llamadas telefónicas<br /><br />
Recopilamos esta información para mejorar su experiencia y habilitar funciones y funcionalidades adicionales de la app VSA, como llamar directamente a un número de teléfono desde la app.<br /><br />
Esta información no es necesaria para usar la app VSA, pero puede mejorar su experiencia de usuario.<br /><br />
Siempre solicitaremos su consentimiento antes de utilizar estos datos.<br /><br />
Información que recopilamos automáticamente del uso de la app VSA<br /><br />
A continuación, encontrará datos personales e información que recopilamos automáticamente de cualquier usuario de la app VSA:<br />
Datos necesarios para utilizar la app VSA<br /><br />
Categorías de datos:<br /><br />
INFORMACIÓN TÉCNICA Y DE USO<br />
Dirección IP, ubicación y hora de acceso<br />
Información del dispositivo (tipo, ID, modelo y fabricante)<br /><br />
Fines de versión de la app:<br /><br />
Recopilamos esta información para habilitar la navegación en la app y el cumplimiento de obligaciones legales.<br />
Datos para mejorar su experiencia con la app VSA<br /><br />
Categorías de datos:<br /><br />
INFORMACIÓN TÉCNICA Y DE USO<br />
Cookies y tecnologías similares para rastrear el uso de la app<br />
Fines: Recopilamos esta información para mantenerlo conectado, recordar sus preferencias, e identificarlo a usted y a su dispositivo.<br />
Siempre solicite su consentimiento antes de utilizar estos datos.<br /><br />
Control de sus datos personales<br />
Puede administrar sus datos para controlar cómo Fluke usa sus datos personales, actualizándolos, eliminando parte de ellos o eliminando su cuenta.<br /><br />
Acceda a su perfil en el panel de la app VSA.<br /><br />
Acerca de sus datos<br />
Es importante aclarar que, según la legislación brasileña, "datos personales" es cualquier información relacionada con una persona natural identificada o identificable. Algunos datos recopilados por nosotros, como el nombre, la dirección de correo electrónico y la dirección IP (Protocolo de Internet), son datos personales.<br />
Sin embargo, no incluye información agregada y no identificable personalmente que no identifica a un usuario o que no se puede vincular razonablemente o de otra manera conectar. Podemos usar dicha información agregada y no identificable personalmente para operar, analizar, mejorar y optimizar la app VSA.<br /><br />
3. Compartir sus datos personales<br />
Podemos compartir los datos personales o información privada del usuario con:<br /><br />
El grupo de empresas Fluke fuera de Brasil, incluida Fluke Corporation, con sede en 6920 Seaway Blvd., Everett, WA 98203, Estados Unidos, y distribuidores locales de Fluke en América Latina, para verificar si el usuario forma parte del canal de ventas de Fluke para América Latina y otorgar acceso a la app, así como determinar información local relevante sobre feriados y promociones que pueden compartirse con los usuarios de la app;<br /><br />
Proveedores de servicios que operan servicios técnicos e infraestructura relacionados con la app VSA, como servicios de seguridad e IT, siempre y cuando acepten restricciones de privacidad similares a las de esta Política de Privacidad, mediante la firma de acuerdos de protección de datos o compromisos similares;<br /><br />
Policía y autoridades de protección de datos, para cumplir con obligaciones legales;<br /><br />
Y/o terceros solo con el consentimiento previo del Usuario.<br /><br />
No vendemos sus datos personales ni información privada.<br /><br />
4. Corrección, retención y eliminación de datos<br />
Si desea corregir, actualizar o eliminar cualquier información personal, comuníquese con marketing@fluke.com.br.<br /><br />
Incluso después de eliminar sus datos personales, podemos conservarlos mediante medidas seguras para cumplir con obligaciones legales o en otra situación permitida por la legislación brasileña.<br /><br />
5. Transferencia a otros países<br />
Podemos transferir los datos personales o la información privada del usuario a otros países para:<br /><br />
Compartirlos con el grupo de empresas Fluke fuera de Brasil, incluida Fluke Corporation, con sede en 6920 Seaway Blvd., Everett, WA 98203, Estados Unidos, y distribuidores locales de Fluke en América Latina, para verificar si el usuario forma parte del canal de ventas de Fluke para América Latina y otorgar acceso a la app, y para determinar información relevante sobre feriados y promociones que pueden compartirse con los usuarios de la app;<br /><br />
Y compartirlos con proveedores de servicios que operan infraestructura técnica relacionada con la app VSA, como servicios de TI y seguridad, siempre y cuando hayan aceptado restricciones de privacidad similares a las de esta Política de Privacidad, mediante la firma de acuerdos de protección de datos o la adopción de compromisos similares.<br /><br />
6. Seguridad y protección<br />
Nos comprometemos a proteger los datos personales del usuario. Tomamos todas las medidas técnicas y organizativas adecuadas para ayudar a proteger la seguridad de sus datos personales, protegerlos contra el acceso no autorizado, la alteración o destrucción, y mantener la precisión de los datos. Para obtener más detalles, consulte la Declaración de Política de Privacidad Global de Fluke en: https://www.fluke.com/en-us/fluke/privacy-policy<br /><br />
Su información se almacena en un servidor seguro y, cada vez que sea necesario identificar o proporcionar información confidencial, los datos se transmitirán entre su dispositivo y la app Fluke a través de una conexión segura.<br /><br />
Sin embargo, recuerde que ningún sistema es 100% seguro. Su contraseña protege su cuenta de usuario. Le recomendamos que siempre use una contraseña fuerte que sea única para su cuenta de la app VSA y nunca comparta su contraseña con nadie.<br /><br />
Toda la información recopilada en las apps se almacenará en nuestra base de datos sin intervención humana. Además, todos los análisis realizados con su información individual también se realizarán mediante procesamiento electrónico, y los resultados presentados siempre serán resúmenes estadísticos, en los que nunca aparecerá su nombre ni ningún otro dato personal.<br /><br />
7. Sus derechos<br />
La legislación brasileña otorga ciertos derechos a las personas respecto a sus datos personales. Por lo tanto, los derechos a continuación se otorgan a todos los usuarios de la app VSA, excepto cuando estén limitados por la ley aplicable:<br /><br />
Derecho a la confirmación: derecho a confirmar la existencia del tratamiento de sus datos personales por nuestra parte;<br /><br />
Derecho de acceso: derecho a ser informado y solicitar acceso a los datos personales que procesamos sobre usted;<br /><br />
Derecho de rectificación: derecho a solicitar que modifiquemos o actualicemos sus datos personales cuando sean inexactos o incompletos;<br /><br />
Derecho al anonimato: derecho a anonimizar, bloquear o eliminar cualquier información o datos innecesarios o en exceso que se procesen en violación de la legislación brasileña;<br /><br />
Derecho a la portabilidad de los datos: derecho a solicitar una copia de sus datos personales en formato electrónico y derecho a transmitir estos datos personales para su uso en el servicio de otra parte;<br /><br />
Derecho al olvido: derecho a solicitar que eliminemos sus datos personales que se estén procesando en base a su consentimiento;<br /><br />
Derecho a la información sobre el intercambio: derecho a solicitar información de las entidades públicas y privadas con las que el responsable del tratamiento haya compartido los datos;<br /><br />
Derecho a la información sobre el consentimiento: derecho a solicitar información sobre la posibilidad de no dar consentimiento y sobre las consecuencias de la negativa; Derecho a revocar el consentimiento: derecho a revocar el consentimiento otorgado previamente.<br /><br />
Si tiene alguna pregunta sobre su privacidad, sus derechos o cómo ejercerlos, comuníquese con nuestro Oficial de Protección de Datos mediante cualquiera de los métodos que se detallan a continuación:<br /><br />
Correo electrónico: marketing@fluke.com.br<br />
Teléfono: +55 11 3530-8901<br /><br />
8. Cambios en esta política<br />
Revisaremos y actualizaremos esta Política periódicamente a la luz de cambios en las prácticas comerciales, la tecnología y los requisitos legales. Si realizamos cambios significativos o materiales, se lo notificaremos por correo electrónico.<br /><br />
9. Cómo ponerse en contacto con nosotros<br />
Si tiene alguna pregunta o inquietud, o desea ejercer sus derechos de privacidad, lo invitamos a comunicarse con nuestro Oficial de Protección de Datos utilizando cualquiera de los métodos que se detallan a continuación:<br /><br />
Correo electrónico: marketing@fluke.com.br<br />
Teléfono: +55 11 3530-8901
`,
}

export default policies