import term from "@/utils/termsText"
import policies from "@/utils/privacyText"

export default {
  general: {
    back: "Back",
  },
  home: {
    title: "New APP FLUKE VSA",
    subtitle: "With the new VSA App you:",
    list: {
      access: "• Have quick access to the entire Fluke product line",
      materials: "• Find support materials for your sales",
      news: "• Receive the latest news from Fluke",
      training: "• Video training",
      catalog: "• Product catalog",
    },
    download: "Download the new Fluke VSA app now"
  },
  deleteAccount: {
    title: "How do I delete my account?",
    list: {
      step1: "Step 1: Log in to your account on Fluke APP VSA",
      step2: "Step 2: Go to Profile Settings",
      step3: "Step 3: Click on the 'Delete Account' button",
      step4: "Step 4: Confirm the deletion of the account",
      step5: "Step 5: Done! Your account has been deleted",
    },
    warning: "This action is irreversible and your data will be permanently deleted.",
  },
  termsOfUse: {
    title: "Terms of Use",
    content: term.en
  },
  privacyPolicy: {
    title: "Privacy Policy",
    content: policies.en
  },
  footer: {
    copyright: "© %{year} Fluke Corporation. All rights reserved.",
    deleteAccount: "How do I delete my account?",
    privacyPolicy: "Privacy Policy",
    termsOfUse: "Terms of Use",
  }
}