<template>
  <div id="app">
    <div v-bind:class="{ 'keep-white': isWhite }" class="wrap">
      <HeaderMenu />
      <router-view />
      <BaseFooter />
    </div>
  </div>
</template>

<script>
import HeaderMenu from "./components/HeaderMenu.vue";
import BaseFooter from "./components/BaseFooter.vue";

export default {
  components: {
    HeaderMenu,
    BaseFooter,
  },
  name: "App",
  data() {
    return {
      title: "Fluke",
    };
  },
  computed: {
    isWhite() {
      console.log(this.$route.meta.layout);
      return this.$route.meta.layout || false;
    },
  },
};
</script>

<style>
@import "./assets/css/style.css";
</style>
