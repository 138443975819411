import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
  {
    path: "/", name: "Home", component: function () {
      return import('../pages/Home.vue')
    }
  },
  {
    path: "/term-of-use", name: "TermOfUse", component: function () {
      return import('../pages/TermUse.vue')
    },
    meta: {
      layout: true
    }
  },
  {
    path: "/privacy-policy", name: "PrivacyPolicy", component: function () {
      return import('../pages/PrivacyPolicy.vue')
    },
    meta: {
      layout: true
    }
  },
  {
    path: "/delete-account", name: "DeleteAccount", component: function () {
      return import('../pages/DeleteAccount.vue')
    },
    meta: {
      layout: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router